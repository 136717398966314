import * as React from 'react'
import { useParams } from 'react-router-dom';
import { ActivityDTORead, ScheduleDTORead } from '../../models/ActivityDTO';
import { LuCalendarDays, LuClock4, LuDumbbell, LuRepeat2, LuHeartPulse, LuLineChart, LuClock, LuAlertCircle, LuAlertTriangle } from "react-icons/lu";
import { Context } from '../../context/appContext';
import { request } from 'http';
import { ScheduleRequest } from '../../context/service.schedule';
import { endpoints } from '../../context/endpoints';
import { FaArrowCircleUp, FaCalendar, FaChartLine, FaClock, FaHeartbeat, FaRegArrowAltCircleLeft, FaRegTimesCircle, FaTachometerAlt } from 'react-icons/fa';
import { DateTimeHelpers } from '../../logic/datetimeHelpers';
import { BiRotateLeft } from 'react-icons/bi';
import Logo from "./../../assets/signe.png"
import { Rating } from '../../components/_shared/Rating';
import { DisplayLeadingZero } from '../../components/_shared/DisplayHelper';
import QRCode from 'react-qr-code';
import { getAutomaticTypeDirectiveNames } from 'typescript';


export function PrintActivity() {
    const [selectedId, setSelectedId] = React.useState<number>(0)
    const routeParams = useParams();
    const [activity, setActivity] = React.useState<ScheduleDTORead | null>(null);
    const { requestToken } = React.useContext(Context);

    React.useEffect(() => {
        if (routeParams.activityId != undefined) {
            let id: number = Number.parseInt(routeParams.activityId)
            setSelectedId(id);
            fetchData(id);

        }
        else {
            setSelectedId(0)
        }
    }, [routeParams])

    const fetchData = async (id: number) => {
        // fetch data
        let token = (await requestToken()).accessToken
        let api = new ScheduleRequest(endpoints.schedule, token);
        let data = await api.get(id);
        setActivity(data);
    }


    if (activity == null) return <div>loading ...</div>

    return <div className='print page'>
        <header>
            <hr />
            <div className='print__logo'>
                <img title='logo' src={Logo} />
            </div>
        </header>
        <main>
            <section className='print__title'>
                <div className='print__title_1'>{activity.Activity.NameDe}</div>
                <div className='print__title_2'>{activity.Activity.NameIt}</div>
                {activity.Activity.IsExternal &&
                    <div className='print__title_warning'>
                        <span><LuAlertTriangle /></span> {activity.Activity.ExternalLogo}</div>
                }
            </section>

            <section className='print__details'>
                <div><LuCalendarDays /> {DateTimeHelpers.GetWeekdayEN(activity.Date)}, {DateTimeHelpers.GetDateString(activity.Date)}</div>
                <div><LuClock4 /> Meeting point: {activity.Activity.MeetingEn} <DisplayLeadingZero value={activity.StartHour} />:<DisplayLeadingZero value={activity.StartMinute} /> | End: <DisplayLeadingZero value={activity.EndHour} />:<DisplayLeadingZero value={activity.EndMinute} /></div>
                {
                    ((activity.Activity.Duration != 0) || (activity.Activity.MeterDifference != 0)) &&
                    <div>
                        <LuRepeat2 />&nbsp;
                        {activity.Activity.Duration != 0 && <>{activity.Activity.Duration} h</>}

                        {activity.Activity.Duration != 0 && activity.Activity.MeterDifference != 0 && <> - </>}

                        {activity.Activity.MeterDifference != 0 && <>{activity.Activity.MeterDifference} hm</>}
                    </div>
                }

                {activity.Activity.Difficulty != 0 &&
                    <div><LuDumbbell />  Difficulty <Rating rating={activity.Activity.Difficulty} /> </div>
                }
                {activity.Activity.TrainingsLevel != 0 &&
                    <div><LuHeartPulse /> Trainings level <Rating rating={activity.Activity.TrainingsLevel} /></div>
                }
                {activity.Activity.TecnicalLevel != 0 &&
                    <div><LuLineChart /> Technical level <Rating rating={activity.Activity.TecnicalLevel} /></div>
                }
            </section>
            <section className='print__description'>
                <div className='print__desc__text'>
                    <b>Beschreibung: </b><span dangerouslySetInnerHTML={{ __html: activity.Activity.DescDe.replace(/\n/g, "<br />") }}></span>
                    {activity.Activity.IsExternal &&

                        <>
                            <hr />
                            <p><b>Anmeldung unter</b></p>
                            <div style={{ width: 80 }}>
                                <QRCode
                                    className='qrCode'
                                    value={activity.Activity.ExternalPageDe}
                                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                                />
                            </div>
                            {activity.Activity.ExternalVoucherCode != '' &&
                                <p>Wählen Sie das Ticket „mit GuestPass museumobil“. Kostenlos mit Promo:<span className="promoBadge">{activity.Activity.ExternalVoucherCode}</span></p>
                            }
                        </>
                    }
                </div>
                <div className='print__desc__text'>
                    <b>Descrizione: </b><span dangerouslySetInnerHTML={{ __html: activity.Activity.DescIt.replace(/\n/g, "<br />") }}></span>
                    {activity.Activity.IsExternal &&

                        <>
                            <hr />
                            <p><b>Prenotazione sotto</b></p>
                            <div className='qrCode' style={{ width: 80 }}>
                                <QRCode
                                    value={activity.Activity.ExternalPageIt}
                                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                                />
                            </div>
                            {activity.Activity.ExternalVoucherCode != '' &&
                                <p>Si sceglie il biglietto „con GuestPass museumobil”. Gratuito con promo:<span className="promoBadge">{activity.Activity.ExternalVoucherCode}</span></p>
                            }
                        </>
                    }
                </div>
            </section>
        </main>
        <footer style={{
            backgroundImage: "url(" + activity.Activity.Url + ")"
        }}>

        </footer>
    </div>
}