import { InteractionType } from '@azure/msal-browser';
import { UnauthenticatedTemplate, AuthenticatedTemplate, useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import * as React from 'react'
import { Card, Form, FormGroup, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { msalScopes } from '../../azure/msal.config';
import './styles.scss'


export function AuthPage() {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();

    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect);
    const runLogin = async () => {
        await login(InteractionType.Redirect, {
            scopes: msalScopes
        })
    }

    const routeToAdmin = () => {
        navigate("/admin")
    }

    return (
        <div
            style={{ minHeight: '100vh' }}
            className='authpage d-flex flex-column justify-content-center h-100 align-items-center'>
            <div className='authpage__shadow'></div>
            <div className='authpage__content'>
                <div className='authpage__block'>
                    <h1>cockpit</h1>
                </div>
                <UnauthenticatedTemplate>
                    <Button onClick={() => runLogin()}>
                        Authenticate
                    </Button>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <Button variant='success' onClick={() => routeToAdmin()} >
                        Go to Dashboard
                    </Button>
                </AuthenticatedTemplate>

            </div>
            <div className='authpage__footer'>
                by Schwarzenstein
            </div>
        </div>
    )

}