import * as React from 'react'
import { Card } from 'react-bootstrap'

interface IProps {
    subline: string
    title:string
    addon?: string
}

export function Widget(p:IProps) {
    return (
        <Card>
            <Card.Body>
                <div>
                    <span style={{fontSize:'2rem'}}>{p.title}</span>
                    {p.addon != undefined && p.addon != '' &&
                    <span>{p.addon}</span>}
                </div>
                <div>{p.subline}</div>
            </Card.Body>
        </Card>
    )
}