import { ActivityDTORead, PicturesDTORead } from "../models/ActivityDTO";
import { ApiResult } from "../models/ApiResult";
import { ApiRequest } from "./service.base";


export class PicturesRequest  extends ApiRequest<PicturesDTORead,ApiResult> {
    constructor(endpoint: string, token: string) {
        super(endpoint, token)
    }

    GetPerSchedule = async (id:number):Promise<PicturesDTORead[]> => {
        let url = this.combineUrl([id.toString()]);
        return this.makeRequest(url, "GET", null);
    }
}