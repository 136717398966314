let weekday = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
let weekdayEN = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]


function DateToISOString(date: Date) {

    let month = ('0' + (date.getMonth() + 1)).slice(-2)
    let day = ('0' + date.getDate()).slice(-2)

    return `${date.getFullYear()}-${month}-${day}`
}

function GetDateString(date: Date | string) {
    if (typeof date == 'string') {
        date = new Date(date);
    }

    let month = ('0' + (date.getMonth() + 1)).slice(-2)
    let day = ('0' + date.getDate()).slice(-2)

    return `${day}.${month}.${date.getFullYear()}`
}

function GetWeekday(date: string | Date) {
    if (typeof date == 'string') {
        date = new Date(date);
    }

    let wd = date.getDay();
    return weekday[wd];
}

function GetWeekdayEN(date: string | Date) {
    if (typeof date == 'string') {
        date = new Date(date);
    }

    let wd = date.getDay();
    return weekdayEN[wd];
}

export const DateTimeHelpers = {
    DateToISOString,
    GetWeekday,
    GetWeekdayEN,
    GetDateString
}