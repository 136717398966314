import * as React from 'react' 
import { Button } from 'react-bootstrap'
import { FaSpinner } from 'react-icons/fa'
import { ApiState } from '../../context/endpoints'


interface IProps {
    onClick: () => void
    text: string
    color: 'primary' | 'danger' | 'warning' | 'dark' | 'secondary'
    working: ApiState
    size?: 'sm'
}
export function ButtonWithSpinner(p:IProps) {

    return (
    <Button size={p.size} className='btn__spinner' variant={p.color} onClick={p.onClick}>
        {p.working == ApiState.working && <FaSpinner className='spin' />}{' '}{p.text}
    </Button>
    )
}