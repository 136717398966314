
export type HttpMethod = 'GET' | "POST" | "PUT" | "DELETE"
export type QueryParams = {key:string, value:string}

// api request base class
export class ApiRequest<TData, TResult> {
    url: string = ''
    token: string = ''


    constructor(endpoint: string, token: string) {
        this.url = endpoint;
        this.token = token
    }

    combineUrl(parts:string[]) {
        let partsCombined = parts.join('/');
        let url = this.url;
        if(url.substring(0,-1) != '/') {
            url += '/'
        }
        url += partsCombined;
        return url;
    }

    makeQuery(url:string, queryParameter:QueryParams[]) {
        let queryArray = queryParameter.map((x) => (x.key+'='+x.value));
        let queryString = '?'+queryArray.join("&");

        return url + encodeURI(queryString);
    }

    makeRequest<T>(url: string, methode: HttpMethod, body: any = null): Promise<T> {
        return fetch(url, {
            method: methode,
            headers: this.prepareHeader(),
            body: body == null ? null : JSON.stringify(body)
        })
        .then<T>(r=> r.json())
    }

    uploadFile(url:string, data:FormData):Promise<TResult> {
        return fetch(url, {
            method: "POST",
            body: data,
            headers: this.prepareHeaderFormData()
        })
        .then<TResult>(r => r.json());
    }

    // get
    get(id: number): Promise<TData> {
        return fetch(this.url + `/${id}`, {
            method: "GET",
            headers: this.prepareHeader()
        })
            .then<TData>(r => r.json())
    }
    // get all
    getAll(): Promise<TData[]> {
        return fetch(this.url, {
            method: "GET",
            headers: this.prepareHeader()
        })
            .then<TData[]>(r => r.json())
    }

    // add
    add(entity: TData): Promise<TResult> {
        return fetch(this.url, {
            method: "POST",
            headers: this.prepareHeader(),
            body: JSON.stringify(entity)
        })
            .then<TResult>(r => r.json())
    }

    // update
    update(entity: TData): Promise<TResult> {
        return fetch(this.url, {
            method: "PUT",
            headers: this.prepareHeader(),
            body: JSON.stringify(entity)
        })
            .then<TResult>(r => r.json())
    }

    // remove
    remove(id: number): Promise<TResult> {
        return fetch(this.url + `/${id}`, {
            method: "DELETE",
            headers: this.prepareHeader()
        })
            .then<TResult>(r => r.json())
    }


    prepareHeader(): any {
        return {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
        }
    }
    prepareHeaderFormData(): any {
        return {
            'Authorization': `Bearer ${this.token}`
            // 'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
}