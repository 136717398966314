import { useMsal } from '@azure/msal-react'
import * as React from 'react'
import { getAccessToken, msalScopes } from '../../azure/msal.config'
import { Col, Row } from 'react-bootstrap';
import { Widget } from '../../components/widget';



export function DashboardPage() {
    const { instance, inProgress } = useMsal()
    const [accessToken, setAccessToken] = React.useState<string>('');

    return (
        <div>
            <h1>Dashboard</h1>
            <Row>
                <Col md>
                    <Widget
                        subline='Programm nachste Woche'
                        title='OK'
                    />
                </Col>
                <Col md></Col>
                <Col md></Col>
            </Row>

        </div>)
}