import * as React from 'react'
import { PictureSingle } from '../../components/pictures/singlePicture';
import { DateTimeHelpers } from '../../logic/datetimeHelpers';
import { PicturesDTORead, ScheduleDTORead } from '../../models/ActivityDTO';
import { ApiState, endpoints } from '../../context/endpoints';
import { Context } from '../../context/appContext';
import { ScheduleRequest } from '../../context/service.schedule';
import { LoadingSpinner } from '../../components/_shared/loadingSpinner';
import { Button, Card, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { FaCamera, FaCheckCircle, FaExclamationCircle, FaRegCircle } from 'react-icons/fa';
import { PicturesRequest } from '../../context/service.pictures';
import { UploadRequest } from '../../context/service.upload';
import { RemoveButton } from '../../components/_shared/actionButtons';



export function PicturesPage() {
    const [f, setF] = React.useState<File[]>([])
    const [curDate, setCurDate] = React.useState<string>(DateTimeHelpers.DateToISOString(new Date()))
    const [scheduleId, setScheduleId] = React.useState<number | null>(null)
    const [schedules, setSchedules] = React.useState<ScheduleDTORead[]>([])
    const [apiState, setApiState] = React.useState<ApiState>(ApiState.working)
    const [lastPictureTimestamp, setLastPictureTimestamp] = React.useState<number>(0);
    const [pictures, setPictures] = React.useState<PicturesDTORead[]>([])
    const [participants, setParticipants] = React.useState<string|null>(null);
    const { requestToken } = React.useContext(Context);


    React.useEffect(() => {
        fetchSchedules();
    }, [])
    React.useEffect(() => {
        fetchSchedules();
    }, [curDate])
    React.useEffect(() => {
        setF([])
    }, [scheduleId])
    React.useEffect(() => {
        fetchPictures();
    }, [scheduleId, lastPictureTimestamp])

    React.useEffect(() => {
        if(scheduleId !== undefined && scheduleId !== null) {
            let filter = schedules.filter(x => x.Id === scheduleId);
            if(filter.length >0) {
                setParticipants((filter[0].Participants === null || filter[0].Participants === undefined ) ? '' : filter[0].Participants.toString());
            }
            else {
                setParticipants('');
            }
        }
    }, [scheduleId, schedules])

    const fetchPictures = async () => {
        if (scheduleId == 0 || scheduleId == null) return;

        let token = (await requestToken()).accessToken;
        let api = new PicturesRequest(endpoints.pictures, token);
        api.GetPerSchedule(scheduleId).then(r => setPictures(r))
    }

    const fetchSchedules = async () => {
        let token = (await requestToken()).accessToken;
        let api = new ScheduleRequest(endpoints.schedule, token);
        let data = await api.getByPeriod(curDate, 1)
        setSchedules(data)
        setApiState(ApiState.none)
    }

    const move = (type: string) => {
        let date: Date = new Date(curDate)
        let amount = 0
        if (type == 'next') amount = 1
        if (type == 'before') amount = -1

        date.setDate(date.getDate() + amount);
        setCurDate(DateTimeHelpers.DateToISOString(date))
        setScheduleId(null)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let files: FileList | null = e.target.files;
        if (files != null) {
            let array: File[] = []
            for (let i = 0; i < files.length; i++) {
                array.push(files[i])
            }
            setF(array);
        }
    }

    const removeImage = async (id: number) => {
        let token = (await requestToken()).accessToken;
        let api = new PicturesRequest(endpoints.pictures, token);
        let result = await api.remove(id);

        if (result.Success) {
            setLastPictureTimestamp(new Date().getMilliseconds());
        }
        else {
            alert(result.ErrorMsg)
        }
    }

    /*
        uploading
    */

    const uploadImages = async () => {
        if (scheduleId === undefined || scheduleId === null) return;

        let token = (await requestToken()).accessToken;
        let api = new UploadRequest(endpoints.upload, token);

        // set api state working
        setApiState(ApiState.working)

        for (let i = 0; i < f.length; i++) {
            let currentFile = f[i];
            let formData = new FormData();

            formData.append("ScheduleId", scheduleId.toString());
            formData.append("File", currentFile);
            formData.append("FileTimeStamp", currentFile.lastModified.toString())
            console.log(formData);
            let uploadResult = await api.uploadImage(formData);
            if (uploadResult.Success == false) {
                alert("Fehler: " + uploadResult.ErrorMsg)
                break;
            }
        }

        // reset api state
        setApiState(ApiState.none)

        setLastPictureTimestamp(new Date().getMilliseconds())
        setF([]);
    }

    const uploadParticipants = async () => {
        if (scheduleId === undefined || scheduleId === null) return;

        let token = (await requestToken()).accessToken;
        let api = new ScheduleRequest(endpoints.schedule, token);

        // set api working
        setApiState(ApiState.working);
        
        // logging
        console.log(participants, "participants")

        let apiresult = await api.publishParticipants(scheduleId, participants === null ? null : parseInt(participants), 'NO NAME');
        if(!apiresult.Success) {
            alert(apiresult.ErrorMsg);
        }
        else {
            console.log(apiresult, "apiresult update participants");
            setLastPictureTimestamp(new Date().getMilliseconds())
            fetchSchedules();
        }

        setApiState(ApiState.none);
    }


    return (
        <div className='picturesPage'>
            <h1>Bilderupload</h1>

            <div className='picturesPage__dateselect mt-3 mb-3'>
                <div className='d-flex justify-content-center gap-4 align-items-center'>
                    <Button size='sm' onClick={() => move('before')}>&lt;&lt;</Button>
                    <span>{DateTimeHelpers.GetWeekday(curDate)} {DateTimeHelpers.GetDateString(curDate)}</span>
                    <Button size='sm' onClick={() => move('next')}>&gt;&gt;</Button>
                </div>
            </div>

            <div className='picturesPage__schedulesliste position-relative'>
                {apiState == ApiState.working && <LoadingSpinner />}

                <Container>

                    <Row className='mt-2'>
                        <Col md={6}>
                            <ListGroup>

                                {schedules && schedules.map((x, i) => {
                                    return (
                                        <ListGroupItem onClick={() => setScheduleId(x.Id)}>
                                            {scheduleId === x.Id ? <FaCheckCircle /> : <FaRegCircle />}
                                            {' '}
                                            {x.Activity.NameDe} | {('0' + x.StartHour).slice(-2)}:{('0' + x.StartMinute).slice(-2)} Uhr | Pax:&nbsp; 
                                            {x.Participants === null ? <span className='text-warning'><FaExclamationCircle /></span> : <span className='text-success fw-bold'>{x.Participants}</span>}
                                            {x.PicturesAmount != null && (x.PicturesAmount > 0) && <span className='float-end'><FaCamera /></span>}
                                        </ListGroupItem>
                                    );
                                })}
                            </ListGroup>

                        </Col>
                        <Col md={6} className='mt-4 mt-md-0'>
                            {scheduleId != null && <div>
                                { /* update participants */}

                                {

                                }
                                <div className='input-group'>
                                    <input className='form-control' inputMode='numeric' type='number' value={participants ?? ''} step={1} onChange={(e) => setParticipants(e.currentTarget.value)}></input>
                                    <div className='input-group-prepend '>
                                        <button className='btn btn-outline-primary' onClick={() => uploadParticipants()}>Teilnehmer speichern</button>
                                    </div>
                                </div>

                                <hr />
                                { /* upload files */}
                                <input className='form-control' type='file' multiple={true} onChange={(e) => handleChange(e)} />

                                {f && f.length > 0 &&
                                    <Card className='mt-4'>
                                        <Card.Header>
                                            {f.length} Dateien hochladen
                                        </Card.Header>
                                        <Card.Body>
                                            <div className='picturesPage__list d-flex flex-wrap'>
                                                {f && f.map((x, i) => {
                                                    return <PictureSingle key={i} file={x} />
                                                })}
                                            </div>
                                        </Card.Body>
                                        <Card.Footer>
                                            {apiState === ApiState.none ?
                                                <Button onClick={() => uploadImages()}>hochladen</Button>
                                                :
                                                <span>uploading ...</span>
                                            }
                                        </Card.Footer>
                                    </Card>
                                }

                            </div>}
                            {pictures.length > 0 && scheduleId !== null &&

                                <div className='d-flex flex-wrap'>
                                    {pictures.map((x, i) =>
                                        <Card className='mt-2  w-100 singlePicture'>
                                            <Card.Body>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <img alt={x.FileName} src={x.Url} />
                                                    <div >
                                                        <RemoveButton text='Löschen' onClick={() => removeImage(x.Id)} />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    )}
                                </div>
                            }
                        </Col>

                    </Row>

                </Container>


            </div>






        </div>
    )
} 