import * as React from 'react'
import { Context } from '../../context/appContext';
import { ActivityRequest } from '../../context/service.activitites';
import { ApiState, endpoints } from '../../context/endpoints';
import { Button, Col, Dropdown, Form, FormControl, FormGroup, FormLabel, Nav, NavItem, Row } from 'react-bootstrap';
import { Rating } from '../_shared/Rating';
import { ActivityDTORead, ActivityGroupDTORead, StatisticGroupDTORead } from '../../models/ActivityDTO';
import { redirect, useNavigate } from 'react-router-dom';
import { ButtonWithSpinner } from '../_shared/ButtonWithSpinner';
import { UploadRequest } from '../../context/service.upload';
import { FaSpinner } from 'react-icons/fa';
import { last } from 'lodash';

interface IProps {
    onUpdate: (id: number) => void
    selectedId: number
    group: ActivityGroupDTORead[]
    statisticgroup: StatisticGroupDTORead[]
}

export function ActivityForm(p: IProps) {
    const [f, setF] = React.useState<File[]>([])

    const [data, setData] = React.useState<ActivityDTORead>({ Difficulty: 0, ImageName: "" } as ActivityDTORead);
    const [showEnglish, setShowEnglish] = React.useState<boolean>(false)
    const { token, requestToken } = React.useContext(Context)
    const navigate = useNavigate();
    const [apiState, setApiState] = React.useState<ApiState>(ApiState.none);
    const [lastPictureTimestamp, setLastPictureTimestamp] = React.useState<number>(0);


    React.useEffect(() => {
        fetchData();
    }, [p.selectedId, lastPictureTimestamp])

    const fetchData = async () => {
        let request = new ActivityRequest(endpoints.actitity, (await requestToken()).accessToken)
        request.get(p.selectedId)
            .then(r => setData(r));
    }

    const updateData = (key: string, value: any) => {
        let newData = Object.assign({ ...data }, { [key]: value })
        setData(newData);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let files: FileList | null = e.target.files;
        if (files != null) {
            let array: File[] = []
            for (let i = 0; i < files.length; i++) {
                array.push(files[i])
            }
            setF(array);
        }
    }

    const submit = async () => {
        setApiState(ApiState.working)

        let d = { ...data };
        if (d.ImageName == null) d.ImageName = '';

        let token = (await requestToken()).accessToken;
        let api = new ActivityRequest(endpoints.actitity, token);
        let request;

        if (p.selectedId == 0) {
            request = api.add(d);
        }
        else {
            request = api.update(d)
        }

        request.then(r => {
            if (r.Success) {
                p.onUpdate(r.EntityId)
                // redirect(r.EntityId.toString())
            }
            else {
                alert(r.ErrorMsg)
            }
        })
            .finally(() => {
                setApiState(ApiState.none)
            })
    }

    const uploadImages = async () => {
        if (data == undefined) return;
        if (f.length == 0) return;
        setApiState(ApiState.working);

        let token = (await requestToken()).accessToken;
        let api = new UploadRequest(endpoints.upload, token);

        for (let i = 0; i < f.length; i++) {
            let currentFile = f[i];
            let formData = new FormData();

            formData.append("ScheduleId", data.Id.toString());
            formData.append("File", currentFile);
            formData.append("FileTimeStamp", currentFile.lastModified.toString())
            console.log(formData);
            let uploadResult = await api.uploadActivityImage(formData);
            if (uploadResult.Success == false) {
                alert("Fehler: " + uploadResult.ErrorMsg)
                break;
            }
        }

        setApiState(ApiState.none);

        setLastPictureTimestamp(new Date().getMilliseconds())
        setF([]);
    }


    return (
        <div className='activity__form p-3'>

            <Row className='mb-3'>
                <Col>
                    <div className='d-flex align-items-center gap-2'>
                        <label>Kategorie</label>
                        <select className='form-control' value={data?.ActivityGroupId} onChange={(e) => updateData('ActivityGroupId', e.currentTarget.value)}>
                            <option>-- Select --</option>
                            {p.group.map((x, i) => {
                                return (<option key={i} value={x.Id}>{x.GroupName}</option>)
                            })}
                        </select>
                    </div>

                </Col>
                <Col>
                    <div className='d-flex align-items-center gap-2'>
                        <label>Statistik</label>
                        <select className='form-control' value={data?.StatisticGroupId} onChange={(e) => updateData('StatisticGroupId', e.currentTarget.value)}>
                            <option>-- Select --</option>
                            {p.statisticgroup.map((x, i) => {
                                return (<option key={i} value={x.Id}>{x.GroupName}</option>)
                            })}
                        </select>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='float-start'>
                        <Form.Check
                            type='switch'
                            id='language'
                            label="Externer Anbieter"
                            checked={data.IsExternal}
                            onClick={() => updateData("IsExternal", !data.IsExternal)}
                        />
                    </div>
                </Col>
                <Col>
                    <div className='float-end'>
                        <Form.Check
                            type='switch'
                            id='language'
                            label="English"
                            onClick={() => setShowEnglish(!showEnglish)}
                        />
                    </div>
                </Col>

            </Row>


            <Row className='mb-3'>
                <Col>
                    <FormGroup>
                        <FormLabel>Name DE</FormLabel>
                        <FormControl size='sm' value={data?.NameDe} onChange={(e) => updateData('NameDe', e.currentTarget.value)}></FormControl>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Meeting DE</FormLabel>
                        <FormControl size='sm' value={data?.MeetingDe} onChange={(e) => updateData('MeetingDe', e.currentTarget.value)}></FormControl>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Beschreibung DE</FormLabel>
                        <FormControl as={'textarea'} rows={8} size='sm' value={data?.DescDe} onChange={(e) => updateData('DescDe', e.currentTarget.value)}></FormControl>
                    </FormGroup>
                    {data.IsExternal &&
                        <FormGroup>
                            <FormLabel>Externe URL DE</FormLabel>
                            <FormControl value={data?.ExternalPageDe} onChange={(e) => updateData('ExternalPageDe', e.currentTarget.value)}></FormControl>
                        </FormGroup>
                    }

                </Col>
                <Col>
                    <div className={showEnglish ? 'd-none' : ''}>
                        <FormGroup>
                            <FormLabel>Name IT</FormLabel>
                            <FormControl size='sm' value={data?.NameIt} onChange={(e) => updateData('NameIt', e.currentTarget.value)}></FormControl>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Meeting IT</FormLabel>
                            <FormControl size='sm' value={data?.MeetingIt} onChange={(e) => updateData('MeetingIt', e.currentTarget.value)}></FormControl>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Beschreibung IT</FormLabel>
                            <FormControl as={'textarea'} rows={8} size='sm' value={data?.DescIt} onChange={(e) => updateData('DescIt', e.currentTarget.value)}></FormControl>
                        </FormGroup>
                        {data.IsExternal &&
                            <FormGroup>
                                <FormLabel>Externe URL IT</FormLabel>
                                <FormControl value={data?.ExternalPageIt} onChange={(e) => updateData('ExternalPageIt', e.currentTarget.value)}></FormControl>
                            </FormGroup>
                        }
                    </div>
                    <div className={showEnglish == false ? 'd-none' : ''}>
                        <FormGroup>
                            <FormLabel>Name EN</FormLabel>
                            <FormControl size='sm' value={data?.NameEn} onChange={(e) => updateData('NameEn', e.currentTarget.value)}></FormControl>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Meeting EN</FormLabel>
                            <FormControl size='sm' value={data?.MeetingEn} onChange={(e) => updateData('MeetingEn', e.currentTarget.value)}></FormControl>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Beschreibung EN</FormLabel>
                            <FormControl as={'textarea'} rows={8} size='sm' value={data?.DescEn} onChange={(e) => updateData('DescEn', e.currentTarget.value)}></FormControl>
                        </FormGroup>
                        {data.IsExternal &&
                            <FormGroup>
                                <FormLabel>Externe URL EN</FormLabel>
                                <FormControl value={data?.ExternalPageEn} onChange={(e) => updateData('ExternalPageEn', e.currentTarget.value)}></FormControl>
                            </FormGroup>
                        }
                    </div>
                </Col>
            </Row>

            <div className='border' ></div>

            {data.IsExternal &&
                <>
                    <Row className='mb-3'>
                        <Col>
                            <FormGroup>
                                <FormLabel>Externer Anbietername</FormLabel>
                                <FormControl value={data?.ExternalLogo} onChange={(e) => updateData('ExternalLogo', e.currentTarget.value)}></FormControl>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <FormLabel>Externer Voucher Code</FormLabel>
                                <FormControl value={data?.ExternalVoucherCode} onChange={(e) => updateData('ExternalVoucherCode', e.currentTarget.value)}></FormControl>
                            </FormGroup>
                        </Col>
                    </Row>

                    <div className='border' ></div>
                </>
            }

            <Row className='mb-3 mt-2'>
                <Col>
                    <div className='d-flex justify-content-between mb-2'>
                        <label>Schwierigkeit</label>
                        <Rating showReset={true} rating={data?.Difficulty} onChange={(e) => updateData("Difficulty", e)} />
                    </div>
                    <div className='d-flex justify-content-between mb-2'>
                        <label>Technik</label>
                        <Rating showReset={true} rating={data?.TecnicalLevel} onChange={(e) => updateData("TecnicalLevel", e)} />
                    </div>
                    <div className='d-flex justify-content-between mb-2'>
                        <label>Kondition</label>
                        <Rating showReset={true} rating={data?.TrainingsLevel} onChange={(e) => updateData("TrainingsLevel", e)} />
                    </div>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Dauer hin und zurück in Stunden</FormLabel>
                        <FormControl type='number' step={'.01'} size='sm' value={data?.Duration} onChange={(e) => updateData('Duration', e.currentTarget.value)}></FormControl>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Höhenmeter</FormLabel>
                        <FormControl type='number' size='sm' value={data?.MeterDifference} onChange={(e) => updateData('MeterDifference', e.currentTarget.value)}></FormControl>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Max Teilnehmer</FormLabel>
                        <FormControl type='number' size='sm' value={data?.MaxParticipants} onChange={(e) => updateData('MaxParticipants', e.currentTarget.value)}></FormControl>
                    </FormGroup>
                </Col>
            </Row>

            {data && data.Id != 0 &&
                <Row className='mb-3'>
                    <Col md={6} sm={12}>
                        <label>Bilder Upload</label>
                        <div>{data.ImageName != null && <img src={data.Url + '?' + lastPictureTimestamp} width={300} />}</div>
                        <div className='d-flex '>
                            <input className='form-control' type='file' onChange={(e) => handleChange(e)} />
                            {apiState == ApiState.none ?
                                <Button onClick={() => uploadImages()}>hochladen</Button>
                                :
                                <span><FaSpinner /></span>
                            }
                        </div>

                    </Col>
                    <Col>

                    </Col>
                </Row>
            }

            <ButtonWithSpinner text='Speichern' color='primary' working={apiState} onClick={() => submit()} />
        </div>
    )
}