import * as React from 'react';
import { Context } from '../../context/appContext';
import { ActivityRequest } from '../../context/service.activitites';
import { ApiState, endpoints } from '../../context/endpoints';
import { Button, FormControl, FormGroup, FormText, InputGroup, ListGroup, ListGroupItem } from 'react-bootstrap';
import { ActivityForm } from '../../components/activity/activityForm';
import { ActivityGroupRequest } from '../../context/service.activititesgroup';
import { ActivityDTORead, ActivityGroupDTORead, StatisticGroupDTORead } from '../../models/ActivityDTO';
import { Link, NavLink, redirect, useNavigate, useParams } from 'react-router-dom';
import { StatisticGroupFrom } from '../../components/statisticGroupForm/statisticGroupForm';
import { StatisticGroupRequest } from '../../context/service.statisticgroup';
import { LoadingSpinner } from '../../components/_shared/loadingSpinner';
import { InputText } from '../../components/_shared/InputText';
import { FaXmark } from 'react-icons/fa6';


export function ActivitiesPage() {
    const [activities, setActivities] = React.useState<ActivityDTORead[]>([])
    const [activityGroup, setActivityGroup] = React.useState<ActivityGroupDTORead[]>([])
    const [statisticGroup, setStatisticGroup] = React.useState<StatisticGroupDTORead[]>([])
    const { token, requestToken } = React.useContext(Context)
    const [selectedId, setSelectedId] = React.useState<number>(0)
    const [search, setSearch] = React.useState<string>('');
    const routeParams = useParams();
    let navigate = useNavigate();
    const [apiState, setApiState] = React.useState<ApiState>(ApiState.none)


    React.useEffect(() => {
        console.log("hit component start ...")
        getData();
    }, [])

    React.useEffect(() => {
        if (routeParams.activityId != undefined) {
            setSelectedId(Number.parseInt(routeParams.activityId));
        }
        else {
            setSelectedId(0)
        }
    }, [routeParams])

    React.useEffect(() => {
        // fetchActivities();


    }, [selectedId])

    const onUpdate = (id: number) => {
        fetchActivities();
        navigate("/admin/activities/" + id)
    }

    const fetchActivities = async () => {
        let token = (await requestToken()).accessToken;
        let api = new ActivityRequest(endpoints.actitity, token)
        api.getAll()
            .then(r => {
                setActivities(r)
            })
    }


    const getData = async () => {
        setApiState(ApiState.working)

        let token = (await requestToken()).accessToken;
        let api = new ActivityRequest(endpoints.actitity, token)
        let acitivityData = await api.getAll();
        setActivities(acitivityData)

        let apigroup = new ActivityGroupRequest(endpoints.actititygroup, token);
        let groupdata = await apigroup.getAll()
        setActivityGroup(groupdata)

        let apiStatisticGroup = new StatisticGroupRequest(endpoints.statisticgroup, token);
        let statisticdata = await apiStatisticGroup.getAll()
        setStatisticGroup(statisticdata)

        setApiState(ApiState.none)
    }

    const filteredItems = activities.filter(x => x.NameDe.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1)


    return (
        <div className='activies'>
            <h1>Aktivitäten</h1>
            <div className='d-flex flex-row'>
                <div className='p-2' style={{ flex: 1, borderRight: 'solid 1px black' }}>
                    <div className='mb-3'>
                        <Button size='sm' onClick={() => navigate('/admin/activities')}>
                            Neues Element
                        </Button>
                        <hr></hr>
                        <InputGroup>
                        <FormControl type='text' value={search} onChange={(e) => setSearch(e.currentTarget.value)} size='sm' /> 
                        <InputGroup.Text onClick={() => setSearch('')}>
                            <FaXmark />
                        </InputGroup.Text>
                        </InputGroup>
                    </div>
                    <div className='position-relative'>
                        {apiState == ApiState.working && <LoadingSpinner />}

                        <ListGroup>
                            {filteredItems.map((x, i) => {
                                return (
                                    <NavLink key={i} className={'list-group-item'} to={'/admin/activities/' + x.Id}>
                                        {x.NameDe} {x.ActivityGroup && <small style={{ fontSize: '.65rem', fontWeight: 'bold', float: 'right' }}>{x.ActivityGroup.GroupName}</small>}
                                    </NavLink>
                                )
                            })}
                        </ListGroup>
                    </div>
                </div>
                <div style={{ flex: 3 }}>
                    <ActivityForm selectedId={selectedId} onUpdate={onUpdate} group={activityGroup} statisticgroup={statisticGroup} />
                </div>
            </div>
        </div>
    )
}