import { AuthenticationResult, BrowserCacheLocation, Configuration, IPublicClientApplication } from "@azure/msal-browser";

export const msalConfig:Configuration = {
    auth: {
        clientId: "0a1621a7-1ab2-40ab-bf6d-ebfda203223d",
        authority: 'https://login.microsoftonline.com/088698a1-2c12-459b-ac80-61a92aa90962'
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
    }
}

export const msalScopes = ["api://0a1621a7-1ab2-40ab-bf6d-ebfda203223d/Basic.Read"]

export const getAccessToken = async (instance:IPublicClientApplication):Promise<AuthenticationResult> => {
    let tokenRequest:AuthenticationResult = await instance.acquireTokenSilent({
        account:instance.getAllAccounts()[0],
        scopes: msalScopes
    })

    return tokenRequest
}