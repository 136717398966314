import * as React from 'react'
import { Badge, Button, FormControl, Modal } from 'react-bootstrap'
import { DateTimeHelpers } from '../../logic/datetimeHelpers'
import { Context } from '../../context/appContext'
import { ScheduleRequest } from '../../context/service.schedule'
import { ApiState, endpoints } from '../../context/endpoints'
import { ScheduleCopyWeekRead } from '../../models/ActivityDTO'


interface IProps {
    date: string
    days: number
    onClose: () => void
}

export function ModalCopyWeek(p: IProps) {
    const [newDate, setNewDate] = React.useState<string>(DateTimeHelpers.DateToISOString(new Date()))
    const { requestToken } = React.useContext(Context);
    const [apiState, setApiState] = React.useState<ApiState>(ApiState.none);


    const copyAction = async () => {
        if (apiState != ApiState.none) return;

        let token = (await requestToken()).accessToken;
        let request = new ScheduleRequest(endpoints.schedule, token);

        let params: ScheduleCopyWeekRead = {
            CopyTo: newDate,
            Days: p.days,
            Start: p.date
        }

        request.copyWeek(params)
            .then(r => {
                if (r.Success) setApiState(ApiState.ok)
                else setApiState(ApiState.error)
            })

    }

    return (
        <Modal show={true} >
            <Modal.Header>Woche kopieren</Modal.Header>
            <Modal.Body>
                <div>
                    <p><b>Datum Start:</b> {DateTimeHelpers.GetWeekday(p.date)}. {DateTimeHelpers.GetDateString(p.date)}</p>
                    <p><b>Tage:</b> {p.days} Tage</p>
                    <hr />
                    <div className='d-flex justiy-content-start align-items-center'>
                        <label>Neues Datum</label>
                        <FormControl type='date' value={newDate} onChange={(e) => setNewDate(e.currentTarget.value)} />
                    </div>
                    {(new Date(p.date).getDay() != new Date(newDate).getDay()) && <Badge bg='warning'>Die Wochentage sind nicht gleich!</Badge>}
                </div>

                <div className='mt-2'>
                    {apiState == ApiState.working && <Badge>loading ...</Badge>}
                    {apiState == ApiState.ok && <Badge bg='success'>OK. Die Woche wurde kopiert</Badge>}
                    {apiState == ApiState.error && <Badge>Fehler: bitte das Fenster schliessen</Badge>}
                </div>

                <div className='d-flex justify-content-between gap-2 mt-4'>
                    <Button onClick={() => copyAction()}>
                        Kopieren
                    </Button>
                    <Button variant='danger' onClick={() => p.onClose()}>
                        Schliessen
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}