

let baseURL = process.env.REACT_APP_API_URL
const adminURL = 'admin'

if (baseURL != null) {
    if (baseURL[-1] == "/") {
        baseURL = baseURL.substring(0, baseURL.length - 2)
    }
}




export const endpoints = {
    actitity: `${baseURL}/${adminURL}/activity`,
    actititygroup: `${baseURL}/${adminURL}/activitygroup`,
    statisticgroup: `${baseURL}/${adminURL}/statisticgroup`,
    schedule: `${baseURL}/${adminURL}/schedule`,
    pictures: `${baseURL}/${adminURL}/pictures`,
    upload: `${baseURL}/${adminURL}/upload`,
}


export enum ApiState {
    none, working, ok, error
}