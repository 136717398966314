import * as React from 'react';
import { Row, Col, FormGroup, Form, FormControl, FormSelect, Button, Dropdown, InputGroup } from 'react-bootstrap';
import { ScheduleDTORead } from '../../models/ActivityDTO';
import { Context } from '../../context/appContext';
import { ScheduleRequest } from '../../context/service.schedule';
import { ApiState, endpoints } from '../../context/endpoints';
import { DateTimeHelpers } from '../../logic/datetimeHelpers';
import * as _ from 'lodash';
import { ScheduleBlock } from '../../components/schedule/scheduleBlock';
import { ScheduleForm } from '../../components/schedule/scheduleForm';
import { LoadingSpinner } from '../../components/_shared/loadingSpinner';
import { ModalCopyWeek } from '../../components/modals/copyWeekModal';
import { Link, Navigate, useNavigate } from 'react-router-dom';

interface IFilter {
    date: string,
    days: number
}
const defaultFilter: IFilter = {
    date: DateTimeHelpers.DateToISOString(new Date()),
    days: 7
}


export function SchedulesPage() {
    const navigate = useNavigate();
    const [filter, setFilter] = React.useState<IFilter>(defaultFilter);
    const [data, setData] = React.useState<ScheduleDTORead[]>([]);
    const { requestToken } = React.useContext(Context);
    const [selectedId, setSelectedId] = React.useState<number>(0);
    const [apiState, setApiState] = React.useState<ApiState>(ApiState.none);
    const [showCopyModal, setCopyModal] = React.useState<boolean>(false);
    const [langs, setLangs] = React.useState<string[]>([]);

    React.useEffect(() => {
        fetchData();
    }, [])

    React.useEffect(() => {
        fetchData();
    }, [filter])

    const toggleLang = (lang: string) => {
        let copyArray = [...langs];
        if (copyArray.indexOf(lang) != -1) {
            copyArray = copyArray.filter(x => x != lang);
        }
        else {
            copyArray.push(lang);
        }
        setLangs(copyArray);
    }

    const updateFilter = (key: string, value: any) => {
        let n = Object.assign({ ...filter }, { [key]: value })
        setFilter(n);
    }

    const updateEntity = (Id: number) => {
        setSelectedId(Id);
        fetchData();
    }

    const fetchData = async () => {
        setApiState(ApiState.working)

        let token = (await requestToken()).accessToken;
        let api = new ScheduleRequest(endpoints.schedule, token);

        api.getByPeriod(filter.date, filter.days)
            .then(r => setData(r))
            .finally(() => {
                setApiState(ApiState.none)
            });
    }

    let dataGroupByDay = _.groupBy(data, e => e.Date);
    let allDates: string[] = [];
    data.map(x => allDates.push(x.Date));

    //let uniqueDates =   _.uniq(allDates).sort();
    let uniqueDates: string[] = []
    for (let i = 0; i < filter.days; i++) {
        let thisDate = new Date(filter.date);
        thisDate.setDate(thisDate.getDate() + i);
        uniqueDates.push(DateTimeHelpers.DateToISOString(thisDate))
    }




    return (
        <div>
            <h1>Zeitplan</h1>
            <Row className='mb-3'>
                <Col>
                    <div className='d-flex gap-2 align-items-end'>
                        <Form className='d-flex gap-2'>
                            <FormGroup >
                                <label>Datum</label>
                                <FormControl type='date' value={filter.date} onChange={(e) => updateFilter('date', e.currentTarget.value)} />
                            </FormGroup>
                            <FormGroup >
                                <label>Tage</label>
                                <FormSelect value={filter.days} onChange={(e) => updateFilter("days", e.currentTarget.value)}>
                                    {[...Array(10)].map((x, i) => {
                                        return (<option value={i + 1}>{i + 1}</option>)
                                    })}
                                </FormSelect>
                            </FormGroup>

                        </Form>

                    </div>

                </Col>
                <Col className='col-auto'>
                    <div className='d-flex gap-2 align-items-end'>
                        <Form className='d-flex align-items-center gap-2' action='/print/week' method='GET'>
                            <Form.Check label='de' name='lang' checked={langs.indexOf('de') == -1 ? false : true} onChange={() => toggleLang('de')} />
                            <Form.Check label='it' name='lang' checked={langs.indexOf('it') == -1 ? false : true} onChange={() => toggleLang('it')} />
                            <Form.Check label='en' name='lang' checked={langs.indexOf('en') == -1 ? false : true} onChange={() => toggleLang('en')} />

                            <Link to={'/print/week?lang=' + langs.join(',') + '&date=' + filter.date + '&days=' + filter.days}
                                className='btn btn-primary'
                                target='_blank'>
                                Drucken
                            </Link>
                        </Form>
                        <Button className='float-end' onClick={() => setCopyModal(true)}>
                            Kopieren
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='position-relative'>
                    {apiState == ApiState.working && <LoadingSpinner />}
                    {uniqueDates.map((x, i) => {
                        return (
                            <ScheduleBlock
                                data={dataGroupByDay[x]}
                                dateString={x}
                                onSelectItem={(e) => setSelectedId(e)}
                                onUpdateEntity={updateEntity}
                                selectedId={selectedId}
                            />
                        )
                    })}
                </Col>
                <Col md='auto' style={{ flexBasis: 380 }}>
                    <ScheduleForm selectedId={selectedId} onUpdate={(e) => updateEntity(e)} />
                </Col>

            </Row>


            {showCopyModal && <ModalCopyWeek onClose={() => setCopyModal(false)} days={filter.days} date={filter.date} />}
        </div>
    )
}