import * as React from 'react'
import { ActivityGroupFrom } from '../../components/activityGroup/activityGroupForm'
import { Row, Col } from 'react-bootstrap'
import { StatisticGroupFrom } from '../../components/statisticGroupForm/statisticGroupForm'



export function SettingsPage() {

    return (
        <div className='settings-page'>
            <h1>Einstellungen</h1>
            <Row>
                <Col><ActivityGroupFrom /></Col>
                <Col><StatisticGroupFrom/></Col>
                <Col></Col>
            </Row>
        </div>
    )
} 


