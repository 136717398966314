import * as React from 'react'
import { NavLink, Outlet } from 'react-router-dom';
import { FaBars, FaBootstrap, FaCamera, FaClock, FaCog, FaHamburger, FaSignOutAlt, FaHome, FaList } from 'react-icons/fa'
import { Context } from './context/appContext';
import { Dropdown } from 'react-bootstrap';

export default function Root() {
  const { sideMenu, setSideMenu } = React.useContext(Context);

  return (
    <div style={{ height: '100vh' }}>
      <div className='d-flex flex-row h-100' style={{ height: '100vh' }}>
        <nav className={"d-flex flex-column flex-shrink-0 bg-body-tertiary " + (sideMenu ? 'open' : '')} style={{ width: "4.5rem" }}>

          <div className='d-flex justify-content-between'>
            <NavLink to="/admin/" className="d-block p-3 link-body-emphasis text-decoration-none" >
              <FaBootstrap size={35} />
            </NavLink>
            <div className='p-3 d-md-none' onClick={() => setSideMenu(!sideMenu)}>
              <FaBars size={35} />
            </div>
          </div>
          <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
            <li className="nav-item">
              <NavLink to="/admin/" onClick={() => setSideMenu(false)} className="nav-link py-3 border-bottom rounded-0 d-flex align-item-center justify-content-center gap-2">
                <FaHome size={25} /> <span className='d-md-none'> Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/admin/activities" onClick={() => setSideMenu(false)} className="nav-link py-3 border-bottom rounded-0 d-flex align-item-center justify-content-center gap-2" >
                <FaList size={25} /><span className='d-md-none'> Aktivitäten</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/schedule" onClick={() => setSideMenu(false)} className="nav-link py-3 border-bottom rounded-0 d-flex align-item-center justify-content-center gap-2" >
                <FaClock size={25} /><span className='d-md-none'> Wochenprogramm</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/pictures" onClick={() => setSideMenu(false)} className="nav-link py-3 border-bottom rounded-0 d-flex align-item-center justify-content-center gap-2" >
                <FaCamera size={25} /><span className='d-md-none'> Bilder</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/settings" onClick={() => setSideMenu(false)} className="nav-link py-3 border-bottom rounded-0 d-flex align-item-center justify-content-center gap-2" >
                <FaCog size={25} /><span className='d-md-none'> Einstellungen</span>
              </NavLink>
            </li>


          </ul>

          <ul className="ms-start nav nav-pills nav-flush flex-column text-center">
            <li className="nav-item">
              <NavLink to="/user/logout" onClick={() => setSideMenu(false)} className="nav-link py-3 border-bottom rounded-0 d-flex align-item-center justify-content-center gap-2">
                <FaSignOutAlt size={25} /> <span className='d-md-none'> Log out</span>
              </NavLink>
            </li>
          </ul>

          
        </nav>
        <main className='mt-md-0 p-2 p-md-5' style={{ flex: 1 }}>
          <Outlet />
        </main>
      </div>
    </div>
  );
}