import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { log } from 'console';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

export function UserLogout() {
    const { instance } = useMsal();

    function signOutClickHandler(instance: IPublicClientApplication) {
        const logoutRequest = {
            //account: instance.getAccountByHomeId(homeAccountId),
            postLogoutRedirectUri: "https://cockpit.api.schwarzenstein.com",
        };
        instance.logoutRedirect(logoutRequest);
    }


    return (
        <div className='d-flex justify-content-center align-items-center vh-100'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <h1>Logout</h1>
                <p>Are you sure you want to logout?</p>
                <div className='d-flex fle-row gap-2'>

                    
                <div className='btn btn-primary' onClick={() => signOutClickHandler(instance)}>Logout</div>
                <NavLink to='/admin/' className='btn btn-secondary'>abbrechen</NavLink>
                </div>
            </div>
        </div>
    );
}