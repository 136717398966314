import * as React from 'react'
import { Button } from 'react-bootstrap'
import { FaPencilAlt, FaPlus, FaPrint, FaTrash } from 'react-icons/fa'


export function ActionButtons(p:{children: JSX.Element | JSX.Element[]})
{
    return (
        <div className='d-flex justify-content-end align-items-center gap-1'>
            {p.children}
        </div>
    )
}
interface IActionButton {
    onClick: ()=> void
    text?: string
}

export function AddButton(p:IActionButton) {
    return <Button variant='primary' size='sm' onClick={() => p.onClick()}>
        <FaPlus/>
    </Button>
}

export function EditButton(p:IActionButton) {
    return <Button variant='warning' size='sm' onClick={() => p.onClick()}>
        <FaPencilAlt/>
    </Button>
}

export function RemoveButton(p:IActionButton) {
    return <Button variant='danger' size='sm' onClick={() => p.onClick()}>
        <FaTrash/> {p.text}
    </Button>
}

export function PrintButton(p:IActionButton) {
    return <Button variant='info' size='sm' onClick={() => p.onClick()}>
        <FaPrint/> {p.text}
    </Button>
}