import "./index.scss";
import "./addons/bootstrap-5.3.1-dist/css/bootstrap.css"
import React, { Children } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import Root from "./root";
import { AuthPage } from "./pages/auth/authPage";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import * as msal from '@azure/msal-browser'
import { DashboardPage } from "./pages/admin/dashboardPage";
import { msalConfig } from "./azure/msal.config";
import { ActivitiesPage } from "./pages/admin/activitiesPage";
import { SchedulesPage } from "./pages/admin/schedulePage";
import { PicturesPage } from "./pages/admin/picturesPage";
import { SettingsPage } from "./pages/admin/settingsPage";
import { AppContextProvider } from "./context/appContext";
import { PrintRoot } from "./pages/print/printRoot";
import { PrintActivity } from "./pages/print/printActivity";
import { PrintWeek } from "./pages/print/printWeek";
import { UserLogout } from "./pages/auth/logoutPage";
import { create } from "lodash";
import RedirectToLogin from "./pages/auth/redirectToLogin";

const routerUnauth = createBrowserRouter([
  {
    path: "/",
    element: <AuthPage />,
  },
  {
    path:"*",
    element: <RedirectToLogin/>
  }
]);

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthPage />,
  },
  {
    path: '/user',
    children: [
      {
        path: "logout",
        element: <UserLogout />
      }]
  },
  {
    path: "/admin",
    element: <Root />,
    children: [
      {
        index: true,
        element: <DashboardPage />
      },
      {
        path: 'activities/:activityId',
        element: <ActivitiesPage />
      },
      {
        path: 'activities',
        element: <ActivitiesPage />
      },
      {
        path: 'schedule',
        element: <SchedulesPage />
      },
      {
        path: 'pictures',
        element: <PicturesPage />
      },
      {
        path: 'settings',
        element: <SettingsPage />
      }
    ]
  },
  {
    path: "/print/",
    element: <PrintRoot />,
    children: [
      {
        path: 'activity/:activityId',
        element: <PrintActivity />
      },
      {
        path: 'week',
        element: <PrintWeek />
      }
    ]
  }
]);

const msalInstance = new msal.PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <AppContextProvider>

      {/* <BrowserRouter >
          <Routes>
              <Route path="*" element={<AuthPage />} />
                <Route path='/admin' element={<Root/>}>
            
                  <Route index element={<AuthenticatedTemplate><DashboardPage /></AuthenticatedTemplate>} />
                  <Route path='activities' element={<AuthenticatedTemplate><ActivitiesPage /></AuthenticatedTemplate>} />
                  <Route path='schedule' element={<AuthenticatedTemplate><SchedulesPage /></AuthenticatedTemplate>} />
                  <Route path='pictures' element={<AuthenticatedTemplate><PicturesPage /></AuthenticatedTemplate>} />
                  <Route path='settings' element={<AuthenticatedTemplate><SettingsPage /></AuthenticatedTemplate>} />
                </Route>
          </Routes>

        </BrowserRouter> */}
      <>
        <UnauthenticatedTemplate>
          <RouterProvider router={routerUnauth} />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <RouterProvider router={router} />
        </AuthenticatedTemplate>
      </>
    </AppContextProvider>
  </MsalProvider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
