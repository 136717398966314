import * as React from 'react';
import {useNavigate} from 'react-router-dom'


export default function RedirectToLogin() {
  const router = useNavigate();
  React.useEffect(() => {
    router('/');
  }, []);
  return <div>Redirecting...</div>;
}