import * as React from 'react'
import { Badge, Button, Col, FormCheck, FormControl, FormGroup, FormSelect, Row } from 'react-bootstrap'
import { Context } from '../../context/appContext'
import { ActivityRequest } from '../../context/service.activitites'
import { ApiState, endpoints } from '../../context/endpoints'
import { ActivityDTORead, ScheduleDTORead } from '../../models/ActivityDTO'
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa'
import { DateTimeHelpers } from '../../logic/datetimeHelpers'
import { ScheduleRequest } from '../../context/service.schedule'
import { ApiResult } from '../../models/ApiResult'
import { ButtonWithSpinner } from '../_shared/ButtonWithSpinner'
import { InputText } from '../_shared/InputText'


interface IScheduleForm {
    selectedId: number
    onUpdate: (Id: number) => void
}

const defaultSchedule: ScheduleDTORead = {
    Id: 0,
    Cancelled: false,
    Published: false,
    Date: DateTimeHelpers.DateToISOString(new Date()),
    StartHour: 0,
    StartMinute: 0,
    EndHour: 0,
    EndMinute: 0,
    ActivityId: 0
} as ScheduleDTORead

const minutesDefault = [0,5,10,15,20,25,30,35,40,45,50,55]

export function ScheduleForm(p: IScheduleForm) {
    const { requestToken } = React.useContext(Context)
    const [activities, setActivities] = React.useState<ActivityDTORead[]>([])
    const [schedule, setSchedule] = React.useState<ScheduleDTORead>(defaultSchedule);
    const [apiState, setApiState] = React.useState<ApiState>(ApiState.none);
    const [search, setSearch] = React.useState<string>('')


    React.useEffect(() => {
        fetchActivities();
    }, [])

    React.useEffect(() => {
        fetchSingle()
    }, [p.selectedId])

    const fetchActivities = async () => {
        let token = (await requestToken()).accessToken;
        let api = new ActivityRequest(endpoints.actitity, token);
        api.getAll().then(r => setActivities(r))
    }

    const fetchSingle = async () => {
        if (p.selectedId != undefined && p.selectedId != 0) {
            setApiState(ApiState.working)
            let token = (await requestToken()).accessToken;
            let api = new ScheduleRequest(endpoints.schedule, token);
            api.get(p.selectedId)
                .then(r => setSchedule(r))
                .finally(() => setApiState(ApiState.none));
        }

    }

    const updateSchedule = (key: string, value: any) => {
        let n = Object.assign({ ...schedule }, { [key]: value });
        setSchedule(n);
    }

    const submit = async () => {
        setApiState(ApiState.working)

        let result: ApiResult;
        let token = (await requestToken()).accessToken;
        let request = new ScheduleRequest(endpoints.schedule, token);
        if (schedule.Id == 0) {
            result = await request.add(schedule)
        }
        else {
            result = await request.update(schedule);
        }

        if (result.Success) {
            p.onUpdate(result.EntityId)
        }
        else {
            alert(result.ErrorMsg)
        }

        setApiState(ApiState.none)
    }

    const remove = async () => {
        let result: ApiResult;
        let token = (await requestToken()).accessToken;
        let request = new ScheduleRequest(endpoints.schedule, token);
        request.remove(p.selectedId)
            .then(r => {
                if (r.Success) {
                    p.onUpdate(0)
                }
            })
    }

    // calculate filtered
    let filteredActivites = activities;
    if (search != '') {
        filteredActivites = activities.filter(x => x.NameDe.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1)
    }


    return (
        <div className='schedule__form'>

            <div className='d-flex justify-content-between align-items-end'>
                {(p.selectedId == undefined || p.selectedId == 0) ? <Badge bg='danger'>Add</Badge> : <Badge bg='warning'>Update</Badge>}
                <Button size='sm' onClick={() => {
                    p.onUpdate(0)
                    setSchedule(defaultSchedule)
                }}>
                    + Add
                </Button>
            </div>
            <Row className='mb-2'>
                <Col>
                    <FormGroup>
                        <label>Datum</label>
                        <FormControl type='date' value={schedule.Date} onChange={(e) => updateSchedule("Date", e.currentTarget.value)} />
                    </FormGroup>
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col>
                    <FormGroup>
                        <label>Von</label>
                        <div className='d-flex gap-2'>
                            <FormSelect value={schedule.StartHour} onChange={(e) => updateSchedule("StartHour", e.currentTarget.value)}>
                                {[...Array(24)].map((x, i) =>
                                    <option key={i} value={i}>{i}</option>
                                )}
                            </FormSelect>
                            <FormSelect value={schedule.StartMinute} onChange={(e) => updateSchedule("StartMinute", e.currentTarget.value)}>
                                {minutesDefault.map((x, i) =>
                                    <option key={i} value={x}>{x}</option>
                                )}
                            </FormSelect>
                        </div>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>Bis</label>
                        <div className='d-flex gap-2'>
                            <FormSelect value={schedule.EndHour} onChange={(e) => updateSchedule("EndHour", e.currentTarget.value)}>
                                {[...Array(24)].map((x, i) =>
                                    <option key={i} value={i}>{i}</option>
                                )}
                            </FormSelect>
                            <FormSelect value={schedule.EndMinute} onChange={(e) => updateSchedule("EndMinute", e.currentTarget.value)}>
                                {minutesDefault.map((x, i) =>
                                    <option key={i} value={x}>{x}</option>
                                )}
                            </FormSelect>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col>
                    <FormCheck checked={schedule.Cancelled} label="ist abgesagt" onChange={(e) => updateSchedule("Cancelled", !schedule.Cancelled)} />
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col>
                    <FormCheck checked={schedule.HasChanged} label="hat sich geändert" onChange={(e) => updateSchedule("HasChanged", !schedule.HasChanged)} />
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col>
                    <FormCheck checked={schedule.Published} label="Freigegeben" onChange={(e) => updateSchedule("Published", !schedule.Published)} />
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col>
                    <label>Aktivität</label>
                    <Row >
                        <Col>
                            <div><b>&gt;</b> {activities && activities.filter(x => x.Id == schedule.ActivityId).map((x => <span>{x.NameDe}</span>))}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormControl placeholder='Suche' value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
                        </Col>
                    </Row>

                    <div className='schedule__activities border'>
                        {filteredActivites && filteredActivites.map((x, i) =>
                            <div
                                onClick={() => updateSchedule('ActivityId', x.Id)}
                                key={i}
                            >{x.Id == schedule.ActivityId ? <FaCheckCircle /> : <FaRegCircle />} {x.NameDe}</div>
                        )}
                    </div></Col>
            </Row>

            <div className='d-flex justify-content-between'>
                <ButtonWithSpinner working={apiState} text='Speichern' size='sm' color='primary' onClick={() => submit()} />

                {p.selectedId != undefined && p.selectedId != 0 &&
                    <Button size='sm' variant='danger' onClick={() => remove()}>
                        Löschen
                    </Button>
                }
            </div>


        </div>
    )
}