import { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import * as React from 'react'
import { msalScopes } from '../azure/msal.config';


interface IAppContext {
    token: AuthenticationResult | null
    requestToken: () => Promise<AuthenticationResult>
    sideMenu: boolean
    setSideMenu: (v: boolean) => void
}

export const Context = React.createContext({} as IAppContext);

export function AppContextProvider(p: { children: React.ReactElement | JSX.Element }) {
    const { instance, inProgress, accounts } = useMsal()
    const [tokenResult, setTokenResult] = React.useState<AuthenticationResult | null>(null)
    const [sideMenu, setSideMenu] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (inProgress != 'none') return
        instance.initialize().then(() => {
            requestToken()
                .then(r => {
                    setTokenResult(r)
                    console.log('token request ok')
                });
        })
    }, [instance, inProgress])

    const msalLogin = async () => {
        await instance.loginRedirect();
    }

    // request token on start
    const requestToken = async (): Promise<AuthenticationResult> => {
        await instance.initialize();
        let result: AuthenticationResult = {} as AuthenticationResult;
        try {
            result = await instance.acquireTokenSilent({
                account: accounts[0],
                scopes: msalScopes
            })
        } catch (e) {
            await instance.loginRedirect({
                scopes: msalScopes
            })
        }

        return result;
    }

    let ctx: IAppContext = {} as IAppContext
    ctx.token = tokenResult;
    ctx.requestToken = requestToken;
    ctx.setSideMenu = setSideMenu;
    ctx.sideMenu = sideMenu;

    return (
        <Context.Provider value={ctx}>
            {p.children}
        </Context.Provider>
    )

}