import { ActivityDTORead, PicturesDTORead } from "../models/ActivityDTO";
import { ApiResult } from "../models/ApiResult";
import { ApiRequest } from "./service.base";


export class UploadRequest  extends ApiRequest<{},ApiResult> {
    constructor(endpoint: string, token: string) {
        super(endpoint, token)
    }

    uploadImage(data:FormData):Promise<ApiResult> {
        let url = this.combineUrl(['pictures']);
        return this.uploadFile(url, data);
    }

    uploadActivityImage(data:FormData):Promise<ApiResult> {
        let url = this.combineUrl(['activity']);
        return this.uploadFile(url, data);
    }
}