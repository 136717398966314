import * as React from 'react'



interface IProps {
    file: File
}

export function PictureSingle(p:IProps) {

    return (
        <div className='singlePicture d-flex p-2 gap-2'>
            <div>
                <img src={URL.createObjectURL(p.file)} width={300} />
            </div>
            <div>
                <p><strong>{p.file.name}</strong><br/>
                {(Math.round(p.file.size/1024))} kb</p>
            </div>
        </div>
    )
}
