
import * as React from 'react'
import { FaRegCircle, FaXmark, FaCircle } from 'react-icons/fa6';

interface IRatingProps {
    showReset?: boolean
    rating: number
    onChange?: (v: number) => void
}
export function Rating(p: IRatingProps) {
    const n = [1, 2, 3, 4, 5];

    const change = (v:number) => {
        if(p.onChange !== undefined) {
            p.onChange(v);
        }
    }

    return (
        <div className='d-inline-block rating'>
            {p.showReset &&
                <FaXmark onClick={() => change(0)} />
            }
            {n.map((x, i) => {
                let selected: boolean = false;
                if (x <= p.rating) {
                    console.log(p.rating)
                    selected = true;
                }

                if (selected)
                    return <FaCircle key={i} onClick={() => change(x)} />
                else
                    return <FaRegCircle key={i} onClick={() => change(x)} />
            })}
        </div>
    )
}