import * as React from 'react'
import { ActivityGroupDTORead } from '../../models/ActivityDTO'
import { Context } from '../../context/appContext';
import { ActivityGroupRequest } from '../../context/service.activititesgroup';
import { endpoints } from '../../context/endpoints';
import { Button, Card, FormControl, Table } from 'react-bootstrap';
import { ActionButtons, EditButton, RemoveButton } from '../_shared/actionButtons';
import { InputText } from '../_shared/InputText';


export function ActivityGroupFrom() {
    const [data, setData] = React.useState<ActivityGroupDTORead[]>([]);
    const { requestToken } = React.useContext(Context);
    const [input, setInput] = React.useState<string>('');

    let apiService: ActivityGroupRequest;

    React.useEffect(() => {
        initService()
    }, [])

    // init service
    const initService = async () => {
        let token = (await requestToken()).accessToken
        apiService = new ActivityGroupRequest(endpoints.actititygroup, token);

        // group
        let groups = await apiService.getAll();
        setData(groups)
    }

    const add = async () => {
        if(input == '') return;

        let token = (await requestToken()).accessToken
        apiService = new ActivityGroupRequest(endpoints.actititygroup, token);

        apiService.add({
            GroupName: input
            
        } as ActivityGroupDTORead)
            .then(r => {
                if (r.Success) {
                    initService();
                }
                else {
                    alert(r.ErrorMsg)
                }
            })
            .finally(() => setInput(''))
    }


    return (
        <Card>
            <Card.Body>
                <h3>Kategorien</h3>

                <div className='mb-3 d-flex'>
                    <FormControl type='text' value={input} onChange={(e) => setInput(e.currentTarget.value)} />
                    <Button onClick={() => add()}>Add</Button>
                </div>

                <table className='table table-sm table-bordered'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Color</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((x, i) => {
                            return (
                                <tr key={i}>
                                    <td>{x.Id}</td>
                                    <td>{x.GroupName}</td>
                                    <td><span style={{background:x.Color, width:10, height:10, display:'inline-block'}}></span></td>
                                    <td>
                                        <ActionButtons>
                                            <EditButton onClick={() => alert('not implemented')} />
                                            <RemoveButton onClick={() => alert('not implemented')} />
                                        </ActionButtons>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Card.Body>
        </Card>
    )
}