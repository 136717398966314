import * as React from 'react'


interface IProps {
    value:number|string
    leadingZeros?: number
}

export function DisplayLeadingZero(p:IProps) {
    let string = p.value.toString();
    string = "0" + string;

    return <>{string.slice(-2)}</>
}