
import * as React from 'react'
import { ScheduleDTORead } from '../../models/ActivityDTO'
import { DateTimeHelpers } from '../../logic/datetimeHelpers'
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa'
import { ActionButtons, EditButton, PrintButton } from '../_shared/actionButtons'
import { Badge } from 'react-bootstrap'
import { Context } from '../../context/appContext'
import { ScheduleRequest } from '../../context/service.schedule'
import { endpoints } from '../../context/endpoints'
import { Link, useNavigate } from 'react-router-dom'

interface IScheduleBlock {
    data: ScheduleDTORead[]
    dateString: string
    onUpdateEntity: (id: number) => void
    onSelectItem: (e: number) => void
    selectedId:number
}

export function ScheduleBlock(p: IScheduleBlock) {

    const { requestToken } = React.useContext(Context);
    const navigate = useNavigate();

    const publishDate = async () => {
        let token = (await requestToken()).accessToken;
        let request = new ScheduleRequest(endpoints.schedule, token);
        request.publishDate(p.dateString)
            .then(r => {
                if (r.Success) p.onUpdateEntity(0);
            })
    }
    const publishItem = async (id: number) => {
        let token = (await requestToken()).accessToken;
        let request = new ScheduleRequest(endpoints.schedule, token);
        request.publishItem(id)
            .then(r => {
                if (r.Success) p.onUpdateEntity(r.EntityId);
            })
    }


    return (
        <div className='schedule__block mb-3 border'>
            <div className='schedule__blockheader d-flex gap-1 justify-content-start border p-2'>
                <div>
                    <span onClick={() => publishDate()}><FaRegCircle /></span>
                </div>
                <div>
                    {DateTimeHelpers.GetWeekday(p.dateString)},  {DateTimeHelpers.GetDateString(p.dateString)}
                </div>

            </div>
            <div className='schedule__blockcontent p-2'>
                <table>
                    <tbody>
                        {p.data && p.data.map((x, i) => {
                            return (
                                <tr key={i} className={p.selectedId == x.Id ? 'bg-secondary' : ''}>
                                    <td style={{ width: 20, textAlign: 'center' }}>
                                        <span onClick={() => publishItem(x.Id)}>{x.Published ? <span className='text-success'><FaCheckCircle /></span> : <FaRegCircle />}</span>
                                    </td>
                                    <td className='schedule__cell-sm'>{('0' + x.StartHour).slice(-2)}:{('0' + x.StartMinute).slice(-2)}</td>
                                    <td className='schedule__cell-sm'>{('0' + x.EndHour).slice(-2)}:{('0' + x.EndMinute).slice(-2)}</td>
                                    <td className='schedule__cell-md'>{x.Activity.ActivityGroup?.GroupName}</td>
                                    <td>{x.Activity.NameDe} {x.Cancelled && <Badge bg='dark'>STORNO</Badge>} {x.HasChanged && <Badge bg='info'>ÄNDERUNG</Badge>}</td>
                                    <td className='schedule__cell-sm'>{x.Activity.Duration}h</td>
                                    <td className='schedule__cell-sm'>
                                        <ActionButtons>
                                            <Link target='_blank' to={'/print/activity/' + x.Id} ><PrintButton onClick={() => console.log('link')} /></Link>
                                            <EditButton onClick={() => p.onSelectItem(x.Id)} />
                                        </ActionButtons>
                                    </td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}