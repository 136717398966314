import { ActivityGroupDTORead, ScheduleCopyWeekRead, ScheduleDTORead, ScheduleParticipantsDTORead, ScheduleWeekRootDTORead, StatisticGroupDTORead } from "../models/ActivityDTO";
import { ApiResult } from "../models/ApiResult";
import { ApiRequest, QueryParams } from "./service.base";


export class ScheduleRequest  extends ApiRequest<ScheduleDTORead,ApiResult> {
    constructor(endpoint: string, token: string) {
        super(endpoint, token)
    }

    getByPeriod(date: string, days: number) : Promise<ScheduleDTORead[]> {
        let query:QueryParams[] = [
            {key: 'date', value: date},
            {key: 'days', value: days.toString()}
        ]
        let url: string = this.makeQuery(this.url, query);
        return this.makeRequest<ScheduleDTORead[]>(url, 'GET');
    }

    publishItem(id:number): Promise<ApiResult> {
        let url = this.combineUrl(['publish', 'item', id.toString()])
        return this.makeRequest<ApiResult>(url, 'POST');
    }

    publishDate(date:string): Promise<ApiResult> {
        let url = this.combineUrl(['publish', 'date', date])
        return this.makeRequest<ApiResult>(url, 'POST');
    }

    /**
     * udpate the participants info and the guide info
     * @param scheduleId 
     * @param participants 
     * @param guide 
     * @returns 
     */
    publishParticipants(scheduleId: number, participants:number|null, guide:string):Promise<ApiResult> {
        let obj:ScheduleParticipantsDTORead = {Participants: participants, Guide:guide} as ScheduleParticipantsDTORead;
        let url = this.combineUrl(['publish', 'participant', scheduleId.toString() ]);
        return this.makeRequest<ApiResult>(url, "POST", obj);
    }

    copyWeek(params:ScheduleCopyWeekRead): Promise<ApiResult> {
        let url = this.combineUrl(['copy', 'week']);
        return this.makeRequest<ApiResult>(url,"POST", params);
    }

    getWeekData(date:string, days:string, lang:string[]): Promise<ScheduleWeekRootDTORead> {
        let query:QueryParams[] = [
            {key: 'date', value: date},
            {key: 'days', value: days.toString()},
            {key: 'lang', value: lang.join(',')}
        ]
        let url: string = this.makeQuery(this.combineUrl(['week']), query);
        return this.makeRequest<ScheduleWeekRootDTORead>(url, 'GET');
    }
}