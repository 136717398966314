
import * as React from 'react'
import { Outlet } from 'react-router-dom'
import './print.scss'

export function PrintRoot() {
    return <div>
        <Outlet />
    </div>
}

